<template>
  <div class="container">
    <h4 class="text-center my-4">Назначение членов комиссии</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else>
      <div v-if="commissionMembers.length" class="my-3">
        <div class="mb-2">Члены комиссии:</div>

        <div v-for="(member, memberIndex) in commissionMembers" :key="memberIndex" class="my-2">
          {{memberIndex+1}}. {{member.lastname}} {{member.firstname}} - {{member.item_name}}
        </div>
      </div>


      <Button v-if="!newMember" icon="pi pi-plus" class="my-4" @click="addMember"/>
      <div v-else>
        <div class="row p-2">
          <div class="col-md-3">
            <Dropdown v-model="newMember.user_id" :options="pps" optionLabel="full_name" optionValue="id"
                      placeholder="Выберите преподавателя" class="w100p"/>
          </div>
          <div class="col-md-3">
            <Dropdown v-model="newMember.role" :options="roles" optionLabel="name" optionValue="name"
                      placeholder="Выберите роль" class="w100p"/>
          </div>

        </div>

        <Button icon="pi pi-save" label="Сохранить" class="my-4" @click="saveMembers"/>
      </div>

    </div>

  </div>
</template>

<script>
  import {mapActions, mapState} from 'vuex'

  export default {
    name: 'CupDean',
    data() {
      return {
        loading: true,
        newMember: null,
        chairmanResearchCup: 'chairmanResearchCup',
        commissionResearchCup: 'commissionResearchCup',
      }
    },
    computed: {
      ...mapState('studentResearchCup', ['commissionMembers', 'roles']),
      ...mapState('academicDebtService', ['academicDebtService']),
      pps() {
        return this.academicDebtService.pps.map(i => ({
          id: i.id,
          full_name: `${i.lastname} ${i.firstname} ${i.middlename}`
        }))
      }
    },
    methods: {
      ...mapActions('studentResearchCup', ['GET_ROLES', 'GET_COMMISSION_MEMBERS', 'POST_COMMISSION_MEMBERS']),
      ...mapActions('academicDebtService', ['GET_PPS']),
      addMember() {
        this.newMember = {
          user_id: null,
          role: this.isSelectedChairman() ? this.commissionResearchCup : null
        }
      },
      isSelectedChairman() {
        return this.commissionMembers.some(member => member.item_name === this.chairmanResearchCup)
      },
      async saveMembers() {
        await this.POST_COMMISSION_MEMBERS([this.newMember])
        this.newMember = null
        await this.GET_COMMISSION_MEMBERS()
      }
    },
    async mounted() {
      await this.GET_PPS(1)
      await this.GET_ROLES()

      await this.GET_COMMISSION_MEMBERS()

      this.loading = false
    },

  }

</script>

<style scoped>

</style>